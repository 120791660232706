import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import mixins from './mixins.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtm from 'vue-gtm'
import VueMeta from 'vue-meta'
import i18n from './i18n'
import VueLocalStorage from 'vue-localstorage'
import '@babel/polyfill'
import SimpleAnalytics from 'simple-analytics-vue'
import VueJsonLD from 'vue-jsonld'

// Cookie notice
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline)

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    arrayRemoveDuplicates (ar = []) {
      return mixins.arrayRemoveDuplicates(ar)
    },
    getObjectWithIDFromArr (ar = [], id) {
      return mixins.getObjectWithIDFromArr(ar, id)
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    trackScreenView (screenName) {
      // console.log('trackScreenView()')
      if (typeof screenName === 'undefined') {
        console.warn('#1 trackScreenView(undefined) -> not calling')
        return
      }
      this.$gtm.trackView(screenName, '')
    },
    trackEvent (action, category = '', label = '', value = '', nonInteraction = false) {
      if (typeof action === 'undefined') {
        console.warn('#2 trackEvent(undefined) -> not calling')
        return
      }
      this.$gtm.trackEvent({
        event: null, // Event type [default = 'interaction'] (Optional)
        category: category,
        action: action,
        label: label,
        value: value,
        noninteraction: nonInteraction // (Optional)
      })
    },
    debounce (func, delay = 300) {
      let debounceTimer
      return function () {
        const context = this
        const args = arguments
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => func.apply(context, args), delay)
      }
    },
    encodeDataForURL (data = {}) {
      return mixins.encodeData(data)
    },
    decodeCode (code = '') {
      return mixins.decodeCode(code)
    },
    isMtabletIncludedInSelection (extModsData, allExtModData) {
      return mixins.isMtabletIncludedInSelection(extModsData, allExtModData)
    },
    removeItemsFromArray (arr = [], arr2 = []) {
      return mixins.removeItemsFromArray(arr, arr2)
    }
  }
})

Vue.use(VueJsonLD)

Vue.use(VueAxios, axios)

Vue.use(SimpleAnalytics, { skip: process.env.NODE_ENV !== 'production' })

Vue.use(VueGtm, {
  id: 'GTM-PD7L8PQ'
})

Vue.use(VueLocalStorage)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
